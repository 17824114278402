<template>
  <div>
    <KTCard ref="preview" v-bind:title="title">

      <!--页面标题-->
      <template v-slot:title>
        <h3 class="card-title">
          <b-icon icon="blockquote-right" class="mx-3"></b-icon>
          信息设置
        </h3>
      </template>

      <!--操作按钮组-->
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <!--按钮组-->

          <!--按钮组-->
        </div>
      </template>

      <!--数据表格-->
      <template v-slot:body>
        <div style="min-height: 70vh">
          <b-row>
            <b-col sm="0"  md="1" lg="1" xl="1" cols="0"></b-col>
            <b-col sm="12"  md="8" lg="8" xl="8" cols="12">
              <b-form id="settingForm" method="POST" class="form" novalidate="novalidate">
                <b-row>
                  <b-col cols="3" class="text-right" align-self="center">
                    <label class="form-group-label">客服QQ:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-row class="m-0 p-0">
                      <b-col sm="12"  md="4" lg="4" xl="4" cols="12" v-for="(item,index) in qqItems" class="mt-0 mb-0 pl-0">
                        <b-form-group class="mb-1">
                          <b-input-group>
                            <b-form-input v-model="item.qq" placeholder="qq号码..."></b-form-input>
                            <template v-slot:append>
                              <b-button variant="danger" size="sm" @click="deleteQQItem(item)">
                                <b-icon icon="x"></b-icon>
                              </b-button>
                            </template>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col cols="1">
                        <b-form-group label="" class="mb-1">
                          <b-button variant="primary" @click="addQQItem()" class="pr-2" v-b-tooltip.hover
                                    title="点击添加">
                            <b-icon icon="plus"></b-icon>
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label class="form-group-label">客服微信:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group description="客服微信二维码，可上传多张">
                      <FileUpload :multiple="true" ref="contactWechat" :uploaded.sync="wechatImage"
                                  maxFileCount="4"></FileUpload>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label class="form-group-label">通知内容:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group
                        description="可用于首页商品下架，新品上架通知"
                    >
                      <b-form-textarea
                          name="roleDesc"
                          type="text"
                          rows="4"
                          v-model="formModel.noticeContent"
                          placeholder="请输入通知内容..."
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-right">
                    <label>通知栏状态:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-radio-group size="lg" name="showContent" v-model="formModel.showContent">
                        <b-form-radio value="1">显示</b-form-radio>
                        <b-form-radio value="0">隐藏</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3">
                  </b-col>
                  <b-col cols="9">
                    <b-button v-on:click="onSubmit" variant="primary">保存</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
            <b-col sm="0"  md="3" lg="3" xl="3" cols="0"></b-col>
          </b-row>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import KTUtil from "@/assets/js/components/util";

import ApiService from "@/core/services/api.service";

// FormValidation plugins
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import Swal from "sweetalert2";
import FileUpload from "@/view/pages/oms_pages/plugin/FileUpload";

export default {
  name: "Edit",
  components: {
    KTCard,
    FileUpload
  },

  props: {
    title: String
  },

  data() {
    return {
      formModel: {
        status: 1,
      },
      qqItems: [{qq: ""}],
      wechatImage: [],
    };
  },

  mounted() {
    // Create a FormValidation instance
    let settingForm = KTUtil.getById("settingForm");
    this.fv = formValidation(settingForm, {
      fields: {
        showContent: {
          validators: {
            notEmpty: {
              message: '通知栏状态不能为空!'
            },
          }
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap()
      },
    });
  },

  created() {
    this.getData();
  },

  methods: {
    addQQItem() {
      this.qqItems.push({qq: ""});
    },

    deleteQQItem(qq) {
      let that = this;
      Swal.fire({
        title: "确定删除?",
        icon: "warning",
        buttons: true,
        showConfirmButton: true,
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        dangerMode: true,
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          let index = that.qqItems.indexOf(qq);
          that.qqItems.splice(index, 1);
        }
      });
    },

    getData() {
      let that = this;
      //获取详情
      ApiService.query("/api/setting/query").then(({data}) => {
        that.formModel = data.result;
        let qqItems = data.result.contactQQ;
        if (qqItems != null && qqItems != undefined && qqItems != "") {
          that.qqItems = JSON.parse(qqItems);
        }

        let images = data.result.contactWechat;
        if (images != null && images != undefined && images != "") {
          let uploaded = [], items = JSON.parse(images);
          items.forEach(function (image) {
            uploaded.push({fileId: image.fileId, imageUrl: image.imageUrl, src: image.imageUrl, uploaded: true});
          });
          that.$refs['contactWechat'].setUploaded(uploaded);
        }

      })
    },

    onSubmit() {
      let that = this;
      this.fv.validate().then(function (status) {
        if (status === 'Valid') {
          that.formModel.contactQQ = JSON.stringify(that.qqItems);
          that.formModel.contactWechat = JSON.stringify(that.wechatImage);
          ApiService.post("/api/setting/manage/update", that.formModel).then(({data}) => {
            if (data.success) {
              Swal.fire({
                title: "",
                text: "保存成功！",
                icon: "success",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000
              })
            } else {
              Swal.fire({
                title: "保存失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000
              });
            }
          })
        }
      });
    }
  },
  computed: {},
  watch: {},
};
</script>
